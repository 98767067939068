import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import BackIcon from "../../assets/images/backIcon.svg";
import ListIcon from "../../assets/images/listIcon.svg";
import StandingImg from "../../assets/images/standing.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Amc8Main() {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <Helmet>
        <meta name="theme-color" content="#f1ebe4" />
      </Helmet>
      <TopContainer>
        <BackBtn
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={BackIcon} />
        </BackBtn>
        <ListBtn>
          <img src={ListIcon} />
        </ListBtn>
      </TopContainer>
      <ContentsContainer>
        <Title>AMC 8</Title>
        <Contents
          slidesPerView={1.3}
          spaceBetween={20}
          centeredSlides={true}
          pagination={true}
          modules={[Pagination]}
        >
          <Content>
            <ContentTitle>AMC 8</ContentTitle>
            <ContentSubTitle>2023 기출 풀이</ContentSubTitle>
            <ContentImg src={StandingImg} />
          </Content>
          <Content>
            <ContentTitle>AMC 8</ContentTitle>
            <ContentSubTitle>2022 기출 풀이</ContentSubTitle>
            <ContentImg src={StandingImg} />
          </Content>
          <Content>
            <ContentTitle>AMC 8</ContentTitle>
            <ContentSubTitle>2020 기출 풀이</ContentSubTitle>
            <ContentImg src={StandingImg} />
          </Content>
        </Contents>
      </ContentsContainer>
    </MainContainer>
  );
}
export default Amc8Main;

const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f1ebe4;
  overflow: hidden;
`;
const TopContainer = styled.div`
  display: flex;
  height: 40px;
  margin-top: 3vh;
  margin-left: 12%;
  margin-right: 12%;
  justify-content: space-between;
  align-items: center;
`;
const BackBtn = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: start;
`;
const ListBtn = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: end;
`;
const ContentsContainer = styled.div`
  width: 100vw;
  height: fit-content;
  margin-top: 8vh;
`;
const Title = styled.p`
  margin: 0;
  font-weight: 900;
  font-size: 30px;
  margin-left: 12%;
  margin-bottom: 20px;
`;
const Contents = styled(Swiper)`
  width: 100vw;
  height: 50vh;
  margin-top: -100px;
  padding-top: 100px;
  padding-bottom: 50px;
`;
const Content = styled(SwiperSlide)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: #d4c5b6;
  box-shadow: 0px 5px 20px 10px rgba(212, 197, 182, 0.3);
  border-radius: 36px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 7px 20px 10px rgba(212, 197, 182, 0.5);
    transform: translateY(-2px) scale(1.01);
  }
`;
const ContentTitle = styled.p`
  position: absolute;
  bottom: 50px;
  left: 30px;
  margin: 0;
  font-weight: 900;
  font-size: 30px;
`;
const ContentSubTitle = styled.p`
  position: absolute;
  bottom: 35px;
  left: 30px;
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
`;
const ContentImg = styled.img`
  position: absolute;
  bottom: -30vh;
  right: -30px;
  height: 70vh;
  pointer-events: none;
`;
