import styled from "styled-components";
import profile from "../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Home() {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <Helmet>
        <meta name="theme-color" content="#d4c5b6" />
      </Helmet>
      <TopContainer>
        <MeContainer>
          <TextContainer>
            <NameText>강유민</NameText>
            <DescriptionText>경기과학고등학교 졸업</DescriptionText>
            <DescriptionText>고려대학교 재학</DescriptionText>
            <DescriptionText>KMO / KOI 수상</DescriptionText>
          </TextContainer>
          <ProfileImg src={profile} />
        </MeContainer>
      </TopContainer>
      <ButtonsContainer>
        <ColumnButtons style={{ marginTop: "36px" }}>
          <Button
            height="133px"
            onClick={() => {
              navigate("/amc8");
            }}
          >
            AMC 8
          </Button>
          <Button height="247px">KMO</Button>
        </ColumnButtons>
        <ColumnButtons style={{ marginLeft: "16px" }}>
          <Button height="150px">AMC 10</Button>
          <Button height="197px">Algorithm</Button>
          <Button height="113px">React</Button>
        </ColumnButtons>
      </ButtonsContainer>
    </MainContainer>
  );
}
export default Home;

const MainContainer = styled.div`
  width: 100vw;
  height: 100%;
  background-color: #f1ebe4;
`;
const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: max-content;
  padding-top: 3vh;
  padding-bottom: 3vh;
  background-color: #d4c5b6;
  border-radius: 0px 0px 0px 40px;
`;
const MeContainer = styled.div`
  display: flex;
  padding-left: 15vw;
  padding-right: 15vw;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;
const NameText = styled.p`
  margin: 0;
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 3px;
`;
const DescriptionText = styled.p`
  margin: 0;
  margin-top: -3px;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
`;
const ProfileImg = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 32px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
  justify-content: center;
  align-items: center;
`;
const ColumnButtons = styled.div`
  display: flex;
  flex-direction: column;
`;
const Button = styled.div`
  cursor: pointer;
  display: flex;
  width: 148px;
  height: ${(props) => props.height};
  margin-bottom: 16px;
  background: #f8f3ef;
  box-shadow: 0px 5px 20px 10px rgba(103, 79, 56, 0.15);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
  color: #84776a;
  &:hover {
    background: #ffffffc0;
    box-shadow: 0px 7px 20px 10px rgba(103, 79, 56, 0.18);
    transform: translateY(-2px);
  }
`;
