import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Amc8Main from "./routes/amc8/Amc8-main";
import Amc82022 from "./routes/amc8/Amc8-2022";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/amc8" element={<Amc8Main />} />
      <Route path="/amc8/2022" element={<Amc82022 />} />
    </Routes>
  );
}

export default App;
